/* Main wrapper for entire policy page */
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');

.policy-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    max-width: 1050px;
    margin: 0 auto;
}
/* Main title */
.policy-content h1 {
 text-align: center;
 font-family: 'Style Script', cursive;
 font-size: 2.5rem;
 font-weight: 1000;
}
.policy-redNote {
    color: red;
}
/* All policy divs */
.policy-general,
.policy-addTransfer,
.policy-latefee,
.policy-cancelation,
.policy-makeup,
.policy-endOfSeason,
.policy-dressCode,
.policy-dropOff {
    border: 2px black solid;
    border-radius: 8px;
    min-height: 100px;
    margin-top: 15px;
}
/* all title elements in each section */
.policy-general h2,
.policy-addTransfer h2,
.policy-latefee h2, 
.policy-cancelation h2,
.policy-makeup h2,
.policy-endOfSeason h2,
.policy-dressCode h2,
.policy-dropOff h2{
    text-align: center;
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
}
/* all paragraph elements in each section */
.policy-general p,
.policy-addTransfer p,
.policy-latefee p,
.policy-cancelation p,
.policy-makeup p,
.policy-endOfSeason p,
.policy-dressCode p,
.policy-dropOff p {
    padding: 5px 5px 10px 10px;
}
.policy-dropOff ul {
    padding-left: 45px;
}
.policy-dropOff li {
    padding-bottom: 10px;
}
.policy-general ul {
    padding-left: 45px;
}
.policy-dressCode p i {
    padding-left: 50px;
}
.policy-dressCode p ul {
    padding-left: 25px;
}
.policy-dressCode p ul li{
    padding-bottom: 10px;
}
.policy-dressCode p ul ul{
    padding-left: 45px;
}