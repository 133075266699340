@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');

.fitness-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}
.fitness-content img{
    width: 80%;
}
.fitness-content h1{
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
    padding-bottom: 10px;
}
.Preschool-content h1{
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
    padding-bottom: 10px;
}
.Preschool-content {
    text-align: center; /* Center align text */
}

.Preschool-content img {
    display: block; /* Make the image a block element */
    margin: 0 auto; /* Center align the image horizontally */
}