@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');

.enroll-body {
    position: relative;
    display: flex;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
}
.enroll-container {
    position: relative;
    max-width: 700px;
    width: 100%;
    background: #e2bede;
    padding: 25px 30px;
    border-radius: 5px;
}
.enroll-topleft-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    float: left;
    align-content: center;
    padding: 10px;
}
.enroll-bottomright-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    float: right;
    align-content: center;
    padding: 10px;
}

.enroll-container .enroll-title {
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
    position: relative;
}
.enroll-container .enroll-title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background: #9b59b6;
}
#enrollment-form {
    display: flex;
    flex-direction: column;
}
.enrollButtonDiv {
    display: flex;
    justify-content: center;
}
#enrollment-form button {
    padding: 10px 0 10px 0;
    border: none;
    background: none;
    margin: 20px 0 20px 0;
    background-color: #B94BA1;
    font-weight: 750;
    font-size: 1.1rem;
    letter-spacing: 1px;
    width: 80%;
}
#enrollment-form button:hover {
    background-color: #bd68aa;
    text-decoration: underline;
    cursor: pointer;
}
.enroll-container form .enroll-user-details {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    margin: 20px 0 12px 0;
}
form .enroll-user-details .enroll-input-box {
    margin-bottom: 15px;
    width: 100%;
}
.enroll-user-details .enroll-input-box .enroll-details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.enroll-comment-box {
    width: 100%;
    font-weight: 500;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.enroll-comment-box span{
    margin-bottom: 5px;
}
.enroll-comment-box textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 5px 5px 15px;
    width: 70%;
    min-height: 120px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    resize: none;
    outline: none;
}
.enroll-user-details .enroll-input-box input {
    height: 45px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding-left: 15px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.enroll-user-details .enroll-input-box input:focus,
.enroll-user-details .enroll-input-box input:focus:valid {
    border-color: #9b59b6;
}
#enrollment-form .enroll-comment-box textarea:focus,
#enrollment-form .enroll-comment-box textarea:focus:valid {
    border-color: #9b59b6;
}
@media (max-width: 650px) {
    .enroll-container {
        max-width: 100%;
    }
    form .enroll-user-details .enroll-input-box {
        margin-bottom: 15px;
        width: 100%n;
    }
    .enroll-topleft-column {
        width: 100%;
    }
    .enroll-bottomright-column {
        width: 100%;
    }

}