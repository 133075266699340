/****************************************************************
 * This is the styling sheet for the regular and summer schedule
 ****************************************************************/
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
.schedule-content {
    justify-content: center;
    padding: 20px 20px;
}
.schedule-gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.0rem;
    justify-content: center;
    margin-top: 20px;
}
.schedule-content table {
    border-collapse: collapse;
    border: black solid 2px;
    margin-bottom: 15px;
    max-width: 365px;
}
.schedule-content td, th {
    border: 1px solid #dddddd;
    padding: 8px;
}
.schedule-content td {
text-align: left;
}
.schedule-content th {
    text-align: center;
}
.schedule-content h1 {
text-align: center;
font-family: 'Style Script', cursive;
font-size: 2.5rem;
font-weight: 1000;
}
.schedule-content h2 {
text-align: center;
font-size: 0.9rem;
font-weight: 1000;
}
.schedule-mondayColumn,
.schedule-tuesdayColumn,
.schedule-wednesdayColumn,
.schedule-thursdayColumn,
.schedule-fridayColumn,
.schedule-saturdayColumn {
    margin: 0 auto;
}
.schedule-mondayColumn h2,
.schedule-tuesdayColumn h2,
.schedule-wednesdayColumn h2,
.schedule-thursdayColumn h2,
.schedule-fridayColumn h2,
.schedule-saturdayColumn  h2{
    font-family: 'Courgette', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
}
@media screen and (max-width: 1090px) {
    .schedule-gridWrapper {
        grid-template-columns: 1fr 1fr;
    }
  }
  @media screen and (max-width: 850px) {
    .schedule-gridWrapper {
        grid-template-columns: 1fr;
    }

    .schedule-gridWrapper h2{
        text-align: center;
    }
  }