@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif; /* This is setting the font for the entire website */
}
.contact {
    position: relative;
    min-height: 80vh;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.contact .content {
    max-width: 800px;
    text-align: center;
}
.contact .content h1 {
    font-family: 'Style Script', cursive;
    font-size: 3.0rem;
    font-weight: 1000;
}
.contact .content h2 {
    font-size: 28px;
    font-weight: 500;
}
.contact.content p{
    font-weight: 300;
}
.contact-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.contact-container .contactInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.contact-container .contactInfo .box{
    position: relative;
    padding: 20px 0;
    display: flex;
}
.contact-container .contactInfo .box .icon{
    min-width: 60px;
    height: 60px;
    background: #e2bede;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
}
.contact-container .contactInfo .box .text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    flex-direction: column;
    font-weight: 300;
}
.contact-container .contactInfo .box .text h3 {
    font-weight: 500;
}
.contactForm {
    width: 40%;
    padding: 40px;
    background: #e2bede;
    border-radius: 5px;
}
.contactButtonDiv {
    display: flex;
    justify-content: center;
}
.contactForm button {
    padding: 10px 5px 10px 5px;
    border: none;
    background: none;
    margin: 20px 0 20px 0;
    background-color: #B94BA1;
    font-weight: 750;
    font-size: 1.1rem;
    letter-spacing: 1px;
}
.contactForm button:hover {
    background-color: #bd68aa;
    text-decoration: underline;
    cursor: pointer;
}
.contactForm h2{
    font-size: 30px;
    font-weight: 500;
}
.contactForm .contact-title {
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
    position: relative;
}
.contactForm .contact-title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background: #9b59b6;
}

.contactForm .inputBox {
    position: relative;
    width: 100%;
    margin-top: 15px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea{
    width: 100% !important;
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    margin: 5px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
    border: black solid 1px;
    border-radius: 2px;
}

.contactForm .inputBox span {
    position: relative;
    left: 0;
    padding: 5px 0 2px 5px;
    font-size: 16px;
    margin: 10px 0 10px 0;
    pointer-events: none;
    transition: 0.5s;
    font-weight: 500;
}
/* .contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
} */
.contactForm .inputBox input[type="submit"] {
    width: 100px;
    background: #00bcd4;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    letter-spacing: 1px;
    font-weight: 700;
}

@media (max-width: 1000px) {
    .contact {
        padding: 50px;
    }
    .contact-container {
        flex-direction: column;
    }
    .contact-container .contactInfo, .contactForm {
        margin-bottom: 40px;
        width: 100%;
    }
}

