@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
#footerWrapper {
    border-top: #706968 3px solid;
    /* border-radius: 0 0 6px 6px; */
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    text-align: center;
    min-height: 150px;
    background-color: #CBBDBA;
    vertical-align: middle;
}
.footer-icon svg{
    text-decoration: none;
    
}
#footerWrapper h1 {
    padding-top: 10px;
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;  
}
.footer-icon a {
    text-decoration: none;
}
.footer-icon {
    padding: 5px 0 5px 0;
}