@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #e2bede;
  padding: 50px 0 50px 0;
}
.wrapper {
  width: 90%;
  max-width: 1000px;
  height: 100%;
  border-radius: 2px;
  margin: 0 auto; /* This keeps 'wrapper' div centered within 'app' div*/
  position: inherit;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid #853474;
}
.content {
  /* width: 100%; */
}
@media screen and (max-width: 755px) {
  .App {
    padding: 0;
  }
  .wrapper {
    width: 100%;
    border-radius: 0;
    border: none;
  }
}
/* #pageTitle {
  text-align: center;
} */
.home-content h1 {
  font-family: 'Style Script', cursive;  
  font-size: 3.5rem;
  font-weight: 1000;
  text-align: center;
}
#welcomeMsg {
  text-align: center;
  padding: 20px;
  min-height: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  background-color: #e2bede;
  border-radius: 4px;
}
#welcomeMsg ul {
  padding-top: 10px;
  list-style: inside;
}
.welcomeImg {
  max-width: 100%;
  text-align: center;
  padding: 10px 10px 10px 10px;
}
.welcomeImg img{
  max-width: 100%;
  border: black 2px solid;
  border-radius: 2px;
  max-height: 500px;
}

* {
    box-sizing: border-box;
  }
  
  .home-container {
    padding: 2.5rem;
    border-top: 2px #333 solid;
    margin-top: 5px;
  }
  
  /* Clear floats */
  .home-row:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .home-column-66 {
    float: left;
    width: 66.66666%;
    padding: 20px;
  }
  .home-column-66 h2 , .card h2{
    font-family: 'Style Script', cursive;  
    font-size: 2.5rem;
  }
  
  /* 1/3 column */
  .home-column-33 {
    float: left;
    width: 33.33333%;
    padding: 20px;
  }
  .home-column-33 img {
    display: block;
    height: auto;
    max-width: 100%;
    border: 2px black solid;
    border-radius: 2px;
  }

  /* Style the counter cards */
  .card {
    padding: 15px;
  }
  .card li{
    padding: 10px;
  }
  .card ul {
    padding: 0 16px 16px 16px;
  }
  .card h2 {
    text-align: center;
  }

  /* Clear floats after the columns */
.card-row:after {
  content: "";
  display: table;
  clear: both;
}

  /* Remove extra left and right margins, due to padding in columns */
.card-row {
  margin: 0 -5px;
  margin-top: 10px;
  border-top: 2px #333 solid;
  margin: 3px 0px 3px 0px;
}

  /* Float four columns side by side */
.card-column {
  float: left;
  width: 50%;
  padding: 0 10px;
}

  /* Add responsiveness - make the columns appear on top of each other instead of next to each other on small screens */
  @media screen and (max-width: 755px) {
    .home-column-66, .home-column-33 {
      width: 100%;
      text-align: center;
    }
    .home-column-33 img {
      margin: auto;
      max-width: 150px;
    }
    .card-column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
* {
    box-sizing: border-box;
  }
  
  .container {
    padding: 1.3rem;
  }
  .row {
    border-bottom: 2px solid #333;
  }
  #staff-firstRow {
    border-top: 2px solid #333;
  }
  .staff-topText {
    text-align: center;
    font-family: 'Style Script', cursive;
    font-size: 3.0rem;
    font-weight: 1000;
    padding-top: 18px;
  }
  /* Clear floats */
  .row:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .staff-column-66 {
    float: left;
    width: 66.66666%;
    padding: 20px;
  }
  .staff-column-66 h2{
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
  }
  
  /* 1/3 column */
  .staff-column-33 {
    float: left;
    width: 33.33333%;
    padding: 20px;
  }
  .staff-column-33 img{
    border: 2px black solid;
    border-radius: 2px;
  }
  
  /* Add responsiveness - make the columns appear on top of each other instead of next to each other on small screens */
  @media screen and (max-width: 900px) {
    .staff-column-66,
    .staff-column-33 {
      width: 100%;
      text-align: center;
    }
  }
.classInfo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    margin: 0 auto;
}
.classInfo-content h1 {
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
    padding-bottom: 10px;
    text-align: center;
}
.enroll-body {
    position: relative;
    display: flex;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
}
.enroll-container {
    position: relative;
    max-width: 700px;
    width: 100%;
    background: #e2bede;
    padding: 25px 30px;
    border-radius: 5px;
}
.enroll-topleft-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    float: left;
    align-content: center;
    padding: 10px;
}
.enroll-bottomright-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    float: right;
    align-content: center;
    padding: 10px;
}

.enroll-container .enroll-title {
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
    position: relative;
}
.enroll-container .enroll-title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background: #9b59b6;
}
#enrollment-form {
    display: flex;
    flex-direction: column;
}
.enrollButtonDiv {
    display: flex;
    justify-content: center;
}
#enrollment-form button {
    padding: 10px 0 10px 0;
    border: none;
    background: none;
    margin: 20px 0 20px 0;
    background-color: #B94BA1;
    font-weight: 750;
    font-size: 1.1rem;
    letter-spacing: 1px;
    width: 80%;
}
#enrollment-form button:hover {
    background-color: #bd68aa;
    text-decoration: underline;
    cursor: pointer;
}
.enroll-container form .enroll-user-details {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    margin: 20px 0 12px 0;
}
form .enroll-user-details .enroll-input-box {
    margin-bottom: 15px;
    width: 100%;
}
.enroll-user-details .enroll-input-box .enroll-details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.enroll-comment-box {
    width: 100%;
    font-weight: 500;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.enroll-comment-box span{
    margin-bottom: 5px;
}
.enroll-comment-box textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 5px 5px 15px;
    width: 70%;
    min-height: 120px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    resize: none;
    outline: none;
}
.enroll-user-details .enroll-input-box input {
    height: 45px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding-left: 15px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.enroll-user-details .enroll-input-box input:focus,
.enroll-user-details .enroll-input-box input:focus:valid {
    border-color: #9b59b6;
}
#enrollment-form .enroll-comment-box textarea:focus,
#enrollment-form .enroll-comment-box textarea:focus:valid {
    border-color: #9b59b6;
}
@media (max-width: 650px) {
    .enroll-container {
        max-width: 100%;
    }
    form .enroll-user-details .enroll-input-box {
        margin-bottom: 15px;
        width: 100%n;
    }
    .enroll-topleft-column {
        width: 100%;
    }
    .enroll-bottomright-column {
        width: 100%;
    }

}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif; /* This is setting the font for the entire website */
}
.contact {
    position: relative;
    min-height: 80vh;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.contact .content {
    max-width: 800px;
    text-align: center;
}
.contact .content h1 {
    font-family: 'Style Script', cursive;
    font-size: 3.0rem;
    font-weight: 1000;
}
.contact .content h2 {
    font-size: 28px;
    font-weight: 500;
}
.contact.content p{
    font-weight: 300;
}
.contact-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.contact-container .contactInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.contact-container .contactInfo .box{
    position: relative;
    padding: 20px 0;
    display: flex;
}
.contact-container .contactInfo .box .icon{
    min-width: 60px;
    height: 60px;
    background: #e2bede;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
}
.contact-container .contactInfo .box .text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    flex-direction: column;
    font-weight: 300;
}
.contact-container .contactInfo .box .text h3 {
    font-weight: 500;
}
.contactForm {
    width: 40%;
    padding: 40px;
    background: #e2bede;
    border-radius: 5px;
}
.contactButtonDiv {
    display: flex;
    justify-content: center;
}
.contactForm button {
    padding: 10px 5px 10px 5px;
    border: none;
    background: none;
    margin: 20px 0 20px 0;
    background-color: #B94BA1;
    font-weight: 750;
    font-size: 1.1rem;
    letter-spacing: 1px;
}
.contactForm button:hover {
    background-color: #bd68aa;
    text-decoration: underline;
    cursor: pointer;
}
.contactForm h2{
    font-size: 30px;
    font-weight: 500;
}
.contactForm .contact-title {
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
    position: relative;
}
.contactForm .contact-title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background: #9b59b6;
}

.contactForm .inputBox {
    position: relative;
    width: 100%;
    margin-top: 15px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea{
    width: 100% !important;
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    margin: 5px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
    border: black solid 1px;
    border-radius: 2px;
}

.contactForm .inputBox span {
    position: relative;
    left: 0;
    padding: 5px 0 2px 5px;
    font-size: 16px;
    margin: 10px 0 10px 0;
    pointer-events: none;
    transition: 0.5s;
    font-weight: 500;
}
/* .contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
} */
.contactForm .inputBox input[type="submit"] {
    width: 100px;
    background: #00bcd4;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    letter-spacing: 1px;
    font-weight: 700;
}

@media (max-width: 1000px) {
    .contact {
        padding: 50px;
    }
    .contact-container {
        flex-direction: column;
    }
    .contact-container .contactInfo, .contactForm {
        margin-bottom: 40px;
        width: 100%;
    }
}



.rates-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    max-width: 1050px;
    margin: 0 auto;
}
.rates-content h1{
    text-align: center;
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
}
/* .rates-paymentMethods {

}
.rates-requiredFees {

} */
.rates-requiredFees p ul{
    padding-left: 40px;
}
.rates-studentHours {
    text-align: center;

}
.rates-studentHours ul{
    /* list-style-type: none; */
    list-style: inside; /* bullet points come to center */
}
.rates-studentHours li{
    padding-bottom: 8px;
}
.rates-studentHours ul{
    padding-left: 45px;
}
/* .rates-familyDiscount {

} */
.rates-familyDiscount p{
    text-align: center;
}
.rates-adultClasses {

    text-align: center;
}
.rates-adultClasses p{
    margin-bottom: 10px;
}
.rates-cancellation {

    margin-bottom: 5px;
}
.rates-cancellation ul{
    padding-left: 40px;
}
.rates-paymentMethods h2,
.rates-requiredFees h2,
.rates-studentHours h2,
.rates-familyDiscount h2,
.rates-adultClasses h2,
.rates-latefees h2,
.rates-cancellation h2 {
    text-align: center;
    padding-bottom: 15px;
    margin-top: 5px;
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
}
.rates-paymentMethods p,
.rates-requiredFees p,
.rates-studentHours p,
.rates-familyDiscount p,
.rates-adultClasses p,
.rates-latefees p,
.rates-cancellation p {
    padding: 5px 5px 5px 10px;
}
.rates-paymentMethods,
.rates-requiredFees,
.rates-studentHours,
.rates-familyDiscount,
.rates-adultClasses,
.rates-latefees,
.rates-cancellation {
    border: 2px black solid;
    border-radius: 8px;
    min-height: 100px;
    margin-top: 15px;
}
/* Main wrapper for entire policy page */

.policy-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    max-width: 1050px;
    margin: 0 auto;
}
/* Main title */
.policy-content h1 {
 text-align: center;
 font-family: 'Style Script', cursive;
 font-size: 2.5rem;
 font-weight: 1000;
}
.policy-redNote {
    color: red;
}
/* All policy divs */
.policy-general,
.policy-addTransfer,
.policy-latefee,
.policy-cancelation,
.policy-makeup,
.policy-endOfSeason,
.policy-dressCode,
.policy-dropOff {
    border: 2px black solid;
    border-radius: 8px;
    min-height: 100px;
    margin-top: 15px;
}
/* all title elements in each section */
.policy-general h2,
.policy-addTransfer h2,
.policy-latefee h2, 
.policy-cancelation h2,
.policy-makeup h2,
.policy-endOfSeason h2,
.policy-dressCode h2,
.policy-dropOff h2{
    text-align: center;
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
}
/* all paragraph elements in each section */
.policy-general p,
.policy-addTransfer p,
.policy-latefee p,
.policy-cancelation p,
.policy-makeup p,
.policy-endOfSeason p,
.policy-dressCode p,
.policy-dropOff p {
    padding: 5px 5px 10px 10px;
}
.policy-dropOff ul {
    padding-left: 45px;
}
.policy-dropOff li {
    padding-bottom: 10px;
}
.policy-general ul {
    padding-left: 45px;
}
.policy-dressCode p i {
    padding-left: 50px;
}
.policy-dressCode p ul {
    padding-left: 25px;
}
.policy-dressCode p ul li{
    padding-bottom: 10px;
}
.policy-dressCode p ul ul{
    padding-left: 45px;
}
.classByAge-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    margin: 0 auto;
}
.classByAge-content h1{
    text-align: center;
    margin-bottom: 25px;
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
}
.classByAge-content table {
    border-collapse: collapse;
    width: 100%;
    border: black solid 2px;
    margin-bottom: 15px;
}
.classByAge-content td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.classByAge-content th {
    text-decoration: underline;
}
@media screen and (max-width: 605px) {
    table {
        font-size: .6rem;
    }
  }
  @media screen and (max-width: 420px) {
    table {
        font-size: .4rem;
    }
  }
  /* Just in case somebody has a freaking tiny screen... */
  @media screen and (max-width: 320px) {
    table {
        font-size: .2rem;
    }
  }   
.fitness-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}
.fitness-content img{
    width: 80%;
}
.fitness-content h1{
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
    padding-bottom: 10px;
}
.Preschool-content h1{
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
    padding-bottom: 10px;
}
.Preschool-content {
    text-align: center; /* Center align text */
}

.Preschool-content img {
    display: block; /* Make the image a block element */
    margin: 0 auto; /* Center align the image horizontally */
}
/****************************************************************
 * This is the styling sheet for the regular and summer schedule
 ****************************************************************/
.schedule-content {
    justify-content: center;
    padding: 20px 20px;
}
.schedule-gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.0rem;
    justify-content: center;
    margin-top: 20px;
}
.schedule-content table {
    border-collapse: collapse;
    border: black solid 2px;
    margin-bottom: 15px;
    max-width: 365px;
}
.schedule-content td, th {
    border: 1px solid #dddddd;
    padding: 8px;
}
.schedule-content td {
text-align: left;
}
.schedule-content th {
    text-align: center;
}
.schedule-content h1 {
text-align: center;
font-family: 'Style Script', cursive;
font-size: 2.5rem;
font-weight: 1000;
}
.schedule-content h2 {
text-align: center;
font-size: 0.9rem;
font-weight: 1000;
}
.schedule-mondayColumn,
.schedule-tuesdayColumn,
.schedule-wednesdayColumn,
.schedule-thursdayColumn,
.schedule-fridayColumn,
.schedule-saturdayColumn {
    margin: 0 auto;
}
.schedule-mondayColumn h2,
.schedule-tuesdayColumn h2,
.schedule-wednesdayColumn h2,
.schedule-thursdayColumn h2,
.schedule-fridayColumn h2,
.schedule-saturdayColumn  h2{
    font-family: 'Courgette', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
}
@media screen and (max-width: 1090px) {
    .schedule-gridWrapper {
        grid-template-columns: 1fr 1fr;
    }
  }
  @media screen and (max-width: 850px) {
    .schedule-gridWrapper {
        grid-template-columns: 1fr;
    }

    .schedule-gridWrapper h2{
        text-align: center;
    }
  }
.performances-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    max-width: 1050px;
    margin: 0 auto;
}
#performances-bottomNote,
#performances-topNote {
    text-align: center;
}


.performances-content h1{
    text-align: center;
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
}
.performances-main,
.performances-recitals {
    border: 2px black solid;
    border-radius: 8px;
    min-height: 100px;
    margin-top: 15px;
}
.performances-main h2,
.performances-recitals h2{
    text-align: center;
    font-family: 'Style Script', cursive;
    font-size: 2.0rem;
    font-weight: 1000;
}
.performances-main p,
.performances-recitals p{
    padding: 5px 5px 10px 10px;
}
.performances-recitals ul {
    padding-left: 45px;
}
.gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.0rem;
    justify-content: center;
    margin: 20px 20px;
}

.gallery-image{
    text-align: center;
}

.gallery-image img{
    border: 2px black solid;
}

.gallery-container {
    display: table;
    border: none;
    
}

.gallery-table {
    display: table-cell;
    vertical-align: middle;
}

.gallery-description {
    text-align: center;
    border: 2px black solid;
    border-radius: 4px;
    padding: 3px 0 3px 0;
}

.gallery-description p{

    padding: 2px;
}

@media screen and (max-width: 800px) {
    /* .gallery-grid {
        grid-template-columns: 1fr 1fr;
    } */
}
@media screen and (max-width: 600px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }
}
.nav{font-weight:700;border-bottom:#333 2px solid;background-color:#CBBDBA}.nav__toggle{position:absolute;cursor:pointer;margin:1.5rem 3.5rem;right:0}.nav__toggle svg{width:2.2rem;fill:#5E5E5E}.nav__toggle .close{display:none}.nav__toggle .menu{margin-top:0.2rem}.nav__checkbox{display:none}.nav__menu{display:flex;flex-direction:column;grid-gap:2rem;gap:2rem;align-items:center;margin:1rem;-webkit-padding-start:0;padding-inline-start:0}.nav__menu li{list-style:none;display:none}.nav__menu li:first-child{margin-right:auto;display:block}.nav__menu li a{font-family:'Courgette', cursive;font-size:1.25rem}.nav__menu li .logo{width:145px;border:3px black solid}.nav__menu li ul.classInfoDropdown{min-width:100px;border:2px solid black;border-radius:2px;padding:5px 5px 5px 5px;background:#dbd1cf;display:none;position:absolute;z-index:999;align-items:center;text-align:left}.nav__menu li ul.classInfoDropdown li{margin:5px 0 5px 0}.nav__menu li:hover ul.classInfoDropdown{display:block}.nav__menu a{text-decoration:none;color:inherit;font-weight:800;font-size:1.05rem}.nav__menu a:hover{color:#d869ba}.nav__menu #logo-anchor-tag:hover{background-color:#CBBDBA;padding:0px}.nav #Navbar-Logo-ListItem{padding-top:14px}#nav__checkbox:checked ~ ul.nav__menu li{display:block}#nav__checkbox:checked ~ label.nav__toggle .close{display:block}#nav__checkbox:checked ~ label.nav__toggle .menu{display:none}@media only screen and (min-width: 755px){.nav{min-height:100px}.nav__toggle{display:none}.nav__menu{flex-direction:row}.nav__menu li{display:block}.nav ul{display:flex;flex-direction:row;align-items:center;justify-content:center;text-align:center}#logo-anchor-tag:hover{background-color:#fff;padding:0px}}

#footerWrapper {
    border-top: #706968 3px solid;
    /* border-radius: 0 0 6px 6px; */
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    text-align: center;
    min-height: 150px;
    background-color: #CBBDBA;
    vertical-align: middle;
}
.footer-icon svg{
    text-decoration: none;
    
}
#footerWrapper h1 {
    padding-top: 10px;
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;  
}
.footer-icon a {
    text-decoration: none;
}
.footer-icon {
    padding: 5px 0 5px 0;
}
