/* #pageTitle {
  text-align: center;
} */
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
.home-content h1 {
  font-family: 'Style Script', cursive;  
  font-size: 3.5rem;
  font-weight: 1000;
  text-align: center;
}
#welcomeMsg {
  text-align: center;
  padding: 20px;
  min-height: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  background-color: #e2bede;
  border-radius: 4px;
}
#welcomeMsg ul {
  padding-top: 10px;
  list-style: inside;
}
.welcomeImg {
  max-width: 100%;
  text-align: center;
  padding: 10px 10px 10px 10px;
}
.welcomeImg img{
  max-width: 100%;
  border: black 2px solid;
  border-radius: 2px;
  max-height: 500px;
}

* {
    box-sizing: border-box;
  }
  
  .home-container {
    padding: 2.5rem;
    border-top: 2px #333 solid;
    margin-top: 5px;
  }
  
  /* Clear floats */
  .home-row:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .home-column-66 {
    float: left;
    width: 66.66666%;
    padding: 20px;
  }
  .home-column-66 h2 , .card h2{
    font-family: 'Style Script', cursive;  
    font-size: 2.5rem;
  }
  
  /* 1/3 column */
  .home-column-33 {
    float: left;
    width: 33.33333%;
    padding: 20px;
  }
  .home-column-33 img {
    display: block;
    height: auto;
    max-width: 100%;
    border: 2px black solid;
    border-radius: 2px;
  }

  /* Style the counter cards */
  .card {
    padding: 15px;
  }
  .card li{
    padding: 10px;
  }
  .card ul {
    padding: 0 16px 16px 16px;
  }
  .card h2 {
    text-align: center;
  }

  /* Clear floats after the columns */
.card-row:after {
  content: "";
  display: table;
  clear: both;
}

  /* Remove extra left and right margins, due to padding in columns */
.card-row {
  margin: 0 -5px;
  margin-top: 10px;
  border-top: 2px #333 solid;
  margin: 3px 0px 3px 0px;
}

  /* Float four columns side by side */
.card-column {
  float: left;
  width: 50%;
  padding: 0 10px;
}

  /* Add responsiveness - make the columns appear on top of each other instead of next to each other on small screens */
  @media screen and (max-width: 755px) {
    .home-column-66, .home-column-33 {
      width: 100%;
      text-align: center;
    }
    .home-column-33 img {
      margin: auto;
      max-width: 150px;
    }
    .card-column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }