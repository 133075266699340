.gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.0rem;
    justify-content: center;
    margin: 20px 20px;
}

.gallery-image{
    text-align: center;
}

.gallery-image img{
    border: 2px black solid;
}

.gallery-container {
    display: table;
    border: none;
    
}

.gallery-table {
    display: table-cell;
    vertical-align: middle;
}

.gallery-description {
    text-align: center;
    border: 2px black solid;
    border-radius: 4px;
    padding: 3px 0 3px 0;
}

.gallery-description p{

    padding: 2px;
}

@media screen and (max-width: 800px) {
    /* .gallery-grid {
        grid-template-columns: 1fr 1fr;
    } */
}
@media screen and (max-width: 600px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }
}