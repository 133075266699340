.App {
  background-color: #e2bede;
  padding: 50px 0 50px 0;
}
.wrapper {
  width: 90%;
  max-width: 1000px;
  height: 100%;
  border-radius: 2px;
  margin: 0 auto; /* This keeps 'wrapper' div centered within 'app' div*/
  position: inherit;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid #853474;
}
.content {
  /* width: 100%; */
}
@media screen and (max-width: 755px) {
  .App {
    padding: 0;
  }
  .wrapper {
    width: 100%;
    border-radius: 0;
    border: none;
  }
}