@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
.classInfo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px;
    margin: 0 auto;
}
.classInfo-content h1 {
    font-family: 'Style Script', cursive;
    font-size: 2.5rem;
    font-weight: 1000;
    padding-bottom: 10px;
    text-align: center;
}